import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ActionPartial,
  ClassnamePartial,
  EnvironmentPartial,
  QaIdPartial,
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Modal = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Modal;
    return <Component {...props} />;
  },
});

const Button = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Button;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/modal.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Modal"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/overlays-dialogs--docs#modal"
      />
      <CodeSnippet
        scope={{ React: React, Modal: Modal, Button: Button }}
        code={snippet}
        platform="react"
        gitHubLink="modal"
      />

      <Section title="Props">
        <PropList header="Visual">
          <ActionPartial
            showButtonType
            showText
            showIcon
            showStatus
            showHref
            showTarget
            showDisabled
            showTooltip
            showQaid
            showOnClick
          />

          <PropListItem name="cancelText" types={['ReactNode']}>
            <Text>
              The translated text shown for the Cancel button. If no translated
              text is provided, "Cancel" will display, by default.
            </Text>
          </PropListItem>

          <PropListItem name="children" types={['ReactNode']} isRequired>
            <Text>What to display as the content of the modal.</Text>
          </PropListItem>

          <ClassnamePartial componentName="modal" />

          <EnvironmentPartial />

          <PropListItem name="header" types={['string']} isRequired>
            <Text>What is displayed in the header as the modal title.</Text>
          </PropListItem>

          <PropListItem name="isOpen" types={['boolean']} isRequired>
            <Text>Determines whether or not the modal is displayed.</Text>
          </PropListItem>

          <PropListItem name="showCancelAction" types={['boolean']}>
            <Text>
              Whether or not to show the cancel button. The cancel button will
              be shown if any actions are provided. If no actions are provided
              you can set this to false and the Cancel action will be hidden as
              well.
            </Text>
          </PropListItem>

          <PropListItem name="size" types={['string']}>
            <Text>
              Determines the size of the modal. This defaults to{' '}
              <code>default</code>, but if you need the modal to be wider, set
              it to large. This will not affect the height of the modal, only
              the width. The height of the modal is determined by the height of
              its content. Once its content reaches a certain height, the modal
              will no longer become taller. Instead, content will be scrollable.
            </Text>
            <List type="unordered">
              <li>
                <code>default</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="supportingText" types={['ReactNode']}>
            <Text>Rendered on the left side of the footer.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Functional">
          <PropListItem name="parentSelector" types={['() => HTMLElement']}>
            <Text>
              A function that will be called to get the parent element that the
              modal will be attached to. By default, it will be attached to the{' '}
              <code>body</code> tag.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onAfterOpen" types={['() => void']}>
            <Text>Called when the modal is opened.</Text>
          </PropListItem>

          <PropListItem name="onClose" types={['() => void']}>
            <Text>
              Function called when the modal is triggered to close. This is
              called if and when the Esc key is pressed, the overlay is clicked,
              the (optional) Cancel button is clicked, or the X is clicked,
              triggering the modal to close. This is where you might update the
              isOpen value to false.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <PropListItem name="label" types={['string']}>
            <Text>
              Value used for the aria-label accessibility tag on the Modal
              element. Defaults to "Hudl Modal".
            </Text>
          </PropListItem>

          <QaIdPartial
            componentName="modal"
            passedDown={['header', 'dismiss', 'support-text']}
          />

          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any of the other props from the{' '}
              <Link href="http://reactcommunity.org/react-modal/">
                React-Modal
              </Link>{' '}
              component.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            The header and footer will remain fixed, even with scrollable
            content.
          </li>
        </List>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Dialogs / Modal"
      />
    </PlatformTab>
  );
};

export default WebTab;
